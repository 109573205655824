import axios from 'axios';
import env from "../config/env";
import cogoToast from 'cogo-toast';

let api =  axios.create({
    baseURL: env.api.baseUrl,
    headers: {
      'X-Client-Id': 'iv-ops-web-app'
    }
});

api.interceptors.response.use(undefined, function (error) {
  console.log(error.message);
  error.handleGlobally = errorComposer(error);
  return Promise.reject(error);
});

export default api;

const errorComposer = (error) => {
  const statusCode = error.response ? error.response.status : null;
  // if (statusCode === 404) {
  //     cogoToast.error('The requested resource does not exist or has been deleted')
  // }
  // if(statusCode === 500  || statusCode === 400 || statusCode === 403){
  //     if(typeof(error?.response?.data) === 'string') {cogoToast.error(error?.response?.data || 'Something Went wrong Please try again.')}
  // }
  // if(error.message === 'Network Error') {
  //     cogoToast.error("Some error occured, please refresh or try to login again")
  // }
};