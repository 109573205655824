import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import "./NoDataBox.scss";
import curveImg from "../../../assets/images/empty-box.svg";

export const RenderNoDataForOverAllDetails = ({firstMessage,secondMessage,handleCallback, callbackTitle,component,lastMessage}) => {
    return (
      <Box display="flex" justifyContent="flex-start" sx={{ height: "150px",background: "#EEF2FF",padding:"30px" }}>
        <img src={curveImg} />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className="no-data-over-all-wrapper"
        >
          <Box>
            <Typography className="head-txt">
              {firstMessage}&nbsp;<span className="text-grey">{secondMessage}</span>{" "}
            </Typography>
            <Typography className="sub-heading-text">
             &nbsp;
             {
              handleCallback?<a className='link' onClick={handleCallback}>{callbackTitle}</a>:component
             }
             &nbsp;
              {lastMessage}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };