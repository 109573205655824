import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./QuestionList.scss";
const NoResult = () => {
  return (
    <Box
      display="flex"
      height="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box className="no_result_heading">Sorry, no results!</Box>
      <Box className="no_result_sub_heading">
        We couldn’t find any questions you are looking for. Please try searching
        with some other keywords.
      </Box>
    </Box>
  );
};

export default NoResult;
