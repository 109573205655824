import React, { Fragment } from "react";

import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  UncontrolledButtonDropdown,
} from "reactstrap";

import {connect, useDispatch} from "react-redux";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logout } from "../../../Apis/auth";
import { setUser } from "../../../reducers/Users";

import avatar1 from "../../../assets/utils/images/logo-iv.png";
import {logoutSuccess} from "../../../reducers/Auth";
import axios from "../../../Apis/axios";

const UserBox = (props) => {
  const dispatch = useDispatch();
  let { setUser, user } = props;

  const handleLogout = () => {
    logout()
      .then((response) => {
        dispatch(logoutSuccess());
        axios.defaults.headers.common['Authorization'] = null;
        console.log(response.data);
      });
    setUser(null);
  };

  return (
    <Fragment>
      <div className="header-btn-lg pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
          <div className="widget-content-left  ml-8 header-user-info">
              <div style={{marginRight:'10px' , color: '#ffffff' , fontWeight: '500'}} className="widget-heading">{user.name}</div>
            </div>
            <div className="widget-content-left">
              <UncontrolledButtonDropdown>
                <DropdownToggle color="link" className="p-0">
                  <img
                    width={42}
                    style={{borderRadius: '4px'}}
                    // className="border-4"
                    src={avatar1}
                    alt=""
                  />
                  <FontAwesomeIcon
                    className="ml-2 opacity-8"
                    icon={faAngleDown}
                  />
                </DropdownToggle>
                <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                  <Nav vertical>
                    <NavItem className="nav-item-header">My Account</NavItem>
                    <NavItem>
                      <NavLink href="#/user/update/password">
                        {/* <NavLink href="auth/login" onClick={handleLogout}> */}
                        Update Password
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="#/auth/login" onClick={handleLogout}>
                        {/* <NavLink href="auth/login" onClick={handleLogout}> */}
                        Logout
                      </NavLink>
                    </NavItem>
                  </Nav>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserBox);
