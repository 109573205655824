const SET_INTERVIEWER = "SET_INTERVIEWER";

export const setInterviewers = (interviewers) => ({
    type: SET_INTERVIEWER,
    payload: interviewers
});

export const interviewers = (state = null, action) => {
    switch (action.type) {
        case SET_INTERVIEWER:
            return action.payload;

        default:
            return state;
    }
};