export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';

export const loginSuccess = (access_token, refresh_token) => ({
  type: AUTH_LOGIN_SUCCESS,
  payload: {
    access_token,
    refresh_token
  }
});

export const logoutSuccess = () => ({
  type: AUTH_LOGOUT_SUCCESS,
  payload: null
});

const initialState = {
  access_token: null,
  refresh_token: null,
};

export const auth = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_LOGIN_SUCCESS:
            return action.payload;

        case AUTH_LOGOUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
};

