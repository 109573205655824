import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
export default function SkeltonLoading() {
  return (
    <Box width="100%">
      <Skeleton variant="h1" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton variant="h1" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton variant="h1" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton variant="h1" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
    </Box>
  );
}
