import React, { Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import PrescreeningPreview from "./Elements/PrescreeningPreview"
import PrescreeningList from "./Elements/PrescreeningList"
import PrescreeningForm from "./Elements/PrescreeningForm"

const Prescreening = ({ match }) => (
    <Fragment>
        <div className="app-main__outer">
            <div className="app-main__inner">
                <Switch>
                    <Route exact path={`${match.url}/preview`} component={PrescreeningPreview} />  
                    <Route exact path={`${match.url}/add`} component={PrescreeningForm} />  
                    <Route path={`${match.url}/`} component={PrescreeningList} />  

                </Switch>
            </div>
        </div>
    </Fragment>
);

export default Prescreening;