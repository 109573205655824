const SET_CLIENT = "SET_CLIENT";

export const setClients = (clients) => ({
    type: SET_CLIENT,
    payload: clients
});

export const clients = (state = [], action) => {
    switch (action.type) {
        case SET_CLIENT:
            return action.payload;

        default:
            return state;
    }
};