import axios from "./axios";
import env from "../config/env";

export const register = newUser => {
    return axios
        .post("/signup", newUser )
        .then(response => {
            console.log('Registered')
        })
};

export const login = user => {
    return axios.post('/login', user)
        .then(response => {
            return response;
        })
        .catch(err => {
            return err.response;
        })
};


export const login_google = () => {
  return axios.get('/ops_auth/google')
      .then(response => {
          return response;
      })
      .catch(err => {
          throw err.response;
      })
};


// api for token and getting access token ....
export const useDataApi = (token) => {
  return axios.get('/google/user/data', {
    headers: {
      'X-Admin-Request-Access-Token': `${token}`,
    }
  })
      .then(response => {
          return response;
      })
      .catch(err => {
          throw err.response;
      })
};


export const refreshAuth = (refresh_token) => {
  return axios.post('/auth/refresh', {}, {
      headers: {
        'Authorization': `Bearer ${refresh_token}`
      }
    })
    .then(response => {
      return response.data;
    })
};

export const update = (payLoad) => {
    return axios.post('/update', payLoad)
        .then(response => {
            return response;
        })
        .catch(err => {
            return err.response;
        })
};

export const logout = () => {
    return axios.get('/logout')
        .then(response => {
            return response;
        })
        .catch(err => {
            return err.response;
        })
};

export const requestClientAccess = (userId) => {
  return axios.get(`/admin/user/${userId}/issue_access_token`)
    .then(response => {
      const { data: token } = response;
      window.open(`${env.apps.client.baseUrl}/authorize?authorization_token=${token}`, '_blank');
    })
    .catch(err => console.warn(err));
};

export const requestInterviewerAccess = (userId) => {
    return axios.get(`/admin/user/${userId}/issue_access_token`)
      .then(response => {
        const { data: token } = response;
        window.open(`${env.apps.interviewer.baseUrl}/authorize?authorization_token=${token}`, '_blank');
        // console.log(`${env.apps.interviewer.baseUrl}/authorize?authorization_token=${token}`, '_blank');
      })
      .catch(err => console.warn(err));
  };

export const createAdminUser = (adminPayload) => {
  return axios.post('/signup/admin', adminPayload)
    .then(response => {
      return response.data;
    });
};
