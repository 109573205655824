import swal from "sweetalert";
import "./SwtAlert.scss";

const SwtAlert = (message, time, type ,buttonEnable) => {
  return swal(message, {
    icon: type,
    buttons: buttonEnable,
    timer: time,
  });
};

export const SwtAlertDescription = (message,description, time, type ,buttonEnable) => {
  return swal(message , description, {
    icon: type,
    buttons: buttonEnable,
    timer: time,
  });
};

export default SwtAlert;
