const config = {
  local: {
    api: {
      baseUrl: "/api",
    },
    apps: {
      client: {
        baseUrl: "http://localhost:3000"
      },
      interviewer: {
        baseUrl: 'http://localhost:3001'
      }
    }
  },
  test: {
    api: {
      baseUrl: "https://test.ops.interviewvector.com/api",
    },
    apps: {
      client: {
        baseUrl: "https://test.app.interviewvector.com"
      },
      interviewer:{
        baseUrl: 'https://test.iv.interviewvector.com'
      }
    }
  },
  stage: {
    api: {
      baseUrl: "https://stage.api.interviewvector.com/api",
    },
    apps: {
      client: {
        baseUrl: "https://stage.app.interviewvector.com"
      },
      interviewer:{
        baseUrl: 'https://stage.iv.interviewvector.com'
      }
    }
  },
  dev: {
    api: {
      baseUrl: "https://dev.api.interviewvector.com/api",
    },
    apps: {
      client: {
        baseUrl: "http://dev.app.interviewvector.com"
      },
      interviewer: {
        baseUrl: 'https://dev.iv.interviewvector.com'
      }
    }
  },
  mirror: {
    api: {
      baseUrl: "https://mirror.api.interviewvector.com/api",
    },
    apps: {
      client: {
        baseUrl: "http://mirror.app.interviewvector.com"
      },
      interviewer: {
        baseUrl: 'https://mirror.iv.interviewvector.com'
      }
    }
  },
  test: {
    api: {
      baseUrl: "https://test.api.interviewvector.com/api",
    },
    apps: {
      client: {
        baseUrl: "http://test.app.interviewvector.com"
      },
      interviewer: {
        baseUrl: 'https://test.iv.interviewvector.com'
      }
    }
  },
  prod: {
    api: {
      baseUrl: "https://api.interviewvector.com/api",
    },
    apps: {
      client: {
        baseUrl: "https://app.interviewvector.com"
      },
      interviewer: {
        baseUrl: 'https://iv.interviewvector.com'
      }
    }
  }
};

const env = process.env.REACT_APP_STAGE || "local";

export default config[env];
