const SET_USER = "SET_USER";

export const setUser = (user) => ({
    type: SET_USER,
    payload: user
});

export const user = (state = null, action) => {
    switch (action.type) {
        case SET_USER:
            return action.payload;

        default:
            return state;
    }
};

