import React, { Fragment } from "react";
import PrescreenPreview from "./Preview";

class Preview extends React.Component {
  render() {
    return (
      <Fragment>
        <PrescreenPreview redirect={this.props} permissions={["ADMIN", "SUPER_ADMIN"]} />
      </Fragment>
    );
  }
}

export default Preview;
