import React, { Fragment } from "react";
import PreForm from "./PrescreeningForm";

class PrescreeningForm extends React.Component {
  render() {
    return (
      <Fragment>
        <PreForm redirect={this.props} permissions={["ADMIN", "SUPER_ADMIN"]} />
      </Fragment>
    );
  }
}

export default PrescreeningForm;
