const RESCHEDULE = "RESCHEDULE";
const SCHEDULE_FOR_CANDIDATE = "SCHEDULE_FOR_CANDIDATE";
const SCHEDULE_FOR_CANDIDATE_DEL = "SCHEDULE_FOR_CANDIDATE_DEL";

export const updateReschedule = (reschedules) => ({
    type: RESCHEDULE,
    payload: reschedules
});

export const scheduleForCandidateData = (candidateData) => ({
    type: SCHEDULE_FOR_CANDIDATE,
    payload: candidateData
});

export const scheduleForCandidateDataDelete = () => ({
    type: SCHEDULE_FOR_CANDIDATE_DEL,
});

export const reschedules = (state = {}, action) => {
    switch (action.type) {
        case RESCHEDULE:
            return action.payload;
        case SCHEDULE_FOR_CANDIDATE:
            return {
                ...state,
                [SCHEDULE_FOR_CANDIDATE]: action.payload
            }
        case SCHEDULE_FOR_CANDIDATE_DEL:
            let newState = { ...state };
            delete newState[SCHEDULE_FOR_CANDIDATE];
            return newState;
        default:
            return state;
    }
};