import "./Stepper.scss";
import { StepperSteps } from "../Constants";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import ArrowBack from '@material-ui/icons/ArrowBackOutlined';
import ArrowForward from '@material-ui/icons/ArrowForwardOutlined';
import seperatorPrescreenSelectedIcon from "../../../../assets/images/separator-guides-selected.svg";
import seperatorPrescreenIcon from "../../../../assets/images/separator-guides.svg";
const Stepper = ({ currentStep, onStepperClick, stepperClickDisable }) => {
  const steps = Object.keys(StepperSteps);

  return (
    <Box display='flex' className='prescreen-stepper-main-container'>

      <div className={`arrow mr-10 ${currentStep === 1 && 'arrow-disabled'} ${stepperClickDisable ? "not-allowed" : "pointer"}`}
        onClick={() => !stepperClickDisable && currentStep > 1 ? onStepperClick(currentStep - 1) : null}> <ArrowBack /></div>

      <Box display='flex' className='stepper-container'>

        {steps?.map((step, index) => {
          return (
            <Box
              display='flex'
              key={index}
              onClick={() => stepperClickDisable ? null : onStepperClick(index + 1)}
              className={`${stepperClickDisable ? "not-allowed" : "pointer"} step-wrapper ${index <= currentStep - 1 && 'step-wrapper-selected'}`}
            >

              {index > 0 &&
                <img src={index <= currentStep ? seperatorPrescreenSelectedIcon : seperatorPrescreenIcon} alt='seperator' />
              }

              <Typography className={`step-label ${index <= currentStep - 1 && 'selected'}`}>
                {StepperSteps[step]?.label}
              </Typography>

            </Box>
          );
        })}
      </Box>
      <div className={`arrow ${currentStep === steps?.length && 'arrow-disabled'} ${stepperClickDisable ? "not-allowed" : "pointer"}`}
        onClick={() => !stepperClickDisable && currentStep < steps?.length ? onStepperClick(currentStep + 1) : null}> <ArrowForward /></div>

    </Box>
  )
}
export default Stepper;