const SET_POSITIONS = "SET_POSITIONS";
const ADD_POSITION = "ADD_POSITION";
const ADD_POSITION_ROUND = "ADD_POSITION_ROUND";

export const setPositions = (positions) => ({
    type: SET_POSITIONS,
    payload: positions
});

export const addPosition = (position) => ({
    type: ADD_POSITION,
    payload: position
});

export const addPositionRound = (positionRound) => ({
    type: ADD_POSITION_ROUND,
    payload: positionRound
});

export const positions = (state = [], action) => {
    switch (action.type) {
        case SET_POSITIONS:
            return action.payload;

        case ADD_POSITION:
            const position = action.payload;
            return state.concat(position);

        case ADD_POSITION_ROUND:
            const posId = action.payload.position_id;
            const new_state = state

            for (let i = 0; i < new_state.length; i++) {
                if (new_state[i].id === posId) {
                    new_state[i].rounds_details.push(action.payload)
                    break
                }
            }
            
            return new_state;
            
            

        default:
            return state;
    }
};

