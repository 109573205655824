export const isAdmin = (user) => {
  let flag = false;

  ["ADMIN", "SUPER_ADMIN"].map((e) => {
    if ((user?.roles[e] !== undefined) === true) {
      flag = true;
    }
  });
  return flag;
};

export const isSuperAdmin = (user) => {
  return user?.roles["SUPER_ADMIN"] !== undefined;
};
